.projects-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .project-card {
    width: 30%;
    margin-bottom: 30px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    overflow: hidden;
    transition: transform 0.3s;
  }
  
  .project-card:hover {
    transform: translateY(-5px);
    cursor: pointer;
  }
  
  .project-card img {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  
  .project-info {
    padding: 20px;
  }
  
  .project-info h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .project-info p {
    font-size: 18px;
    line-height: 1.4;
    margin-bottom: 0;
  }
  h2{
    color: yellow;
  }