body {
  background: linear-gradient(0.4turn, black, black, grey);
  font-family: "Lucida Handwriting", "cursive";
  color: #333;
  margin: 0;
  padding: 0;
  height: 100%;

}

.app-container {
  margin: 0 auto;
  padding: 50px 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

}

.content-container {
  margin: 0 auto;
  padding: 50px 20px;
}

html{
  margin: 0;
  padding: 0;
}

#root {
  height: 100%;
}

