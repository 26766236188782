  
  .homepage::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: -1;
  }

  .interest-image {
    display: block;
    width: 100%;
    border-radius: 10px;
    transition: transform 0.5s;
  }
  
  .image-container:hover .interest-image {
    transform: translateZ(50px);
  }
  
  .image-container:hover {
    cursor: pointer;
    animation-name: tilt;
    animation-duration: 0.5s;
    animation-timing-function: ease-out;
    animation-fill-mode: forwards;
  }
  
  @keyframes tilt {
    0% {
      transform: none;
    }
    100% {
      transform: rotateX(-10deg) rotateY(10deg) rotateZ(0deg);
    }
  }


  .btn-warning {
    color: #ffd700;
    background-color: transparent;
    border-color: #ffd700;
    transition: all 0.5s;
  }
  
  .btn-warning:hover {
    background-color: #ffd700;
    color: black;
  }

  .yellow-button {
    color: #ffd700;
    background-color: transparent;
    border-color: #ffd700;
    transition: all 0.5s;
  }
  
  .yellow-button:hover {
    background-color: #ffd700;
    color: black;
  }